<template>
    <div class="task_wrapper">
        <div class="task_full_body_wrapper h-full">
            <taskList
                :taskType="taskType" 
                showPageTitle
                :page_name="page_name" 
                :pageConfig="pageConfig">
                <template>
                    <PageFilter 
                        model="tasks.TaskModel"
                        :key="page_name"
                        size="large"
                        :page_name="page_name" />
                </template>
            </taskList>
        </div>
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import pageMeta from '@/mixins/pageMeta'
import { mapState } from 'vuex'
export default {
    mixins: [pageMeta],
    components: {
        taskList: () => import('@apps/vue2TaskComponent/views/Table/Page.vue'),
        PageFilter
    },
    computed: {
        ...mapState({
            viewType: state => state.task.mobileViewType
        }),
        getRouteInfo() {
            return this.$store.getters['navigation/getRouteInfo'](this.$route.name)
        },
        page_name() {
            return `page_list_${this.taskType}_task.TaskModel`
        },
        taskType() {
            return this.getRouteInfo?.task_type || 'task'
        },
        pageConfig() {
            return this.$route.meta?.pageConfig ? this.$route.meta.pageConfig : null
        }
    }
}
</script>